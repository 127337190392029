import React, { useRef, useEffect } from "react";

const DraggableJaw = ({ size, brushingResult, colorRange }) => {
  const elementRef = useRef(null);

  useEffect(() => {
    const jaw = elementRef.current;
    jaw.brushingResult = brushingResult;
    jaw.colorRange = colorRange;
  }, [brushingResult, colorRange]);

  return (
    <kl-draggable-jaw
      key={`${colorRange.start}${colorRange.end}`}
      size={size}
      ref={elementRef}
      colorRange={colorRange}
    ></kl-draggable-jaw>
  );
};

export default DraggableJaw;
