import React, { Component } from "react";
import CustomizedProgressBars from "./progressBar";
import { styled } from "@mui/material/styles";
import { withStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import DraggableJaw from "../../../components/kolibree/websdk/draggable-jaw";
import Paper from "@mui/material/Paper";
import "./details.css";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import {
  Card, CardContent, ClickAwayListener, Grid, TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import TimeUtil from "../../../utils/time";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {
  CoverageMinMaxFilter, DurationMinMaxMinutesSecondsFilter,
} from "../../../filters/brushings";
import "./../../../App.css";
import "./coverageMap.css";
import { theme, styles } from "./coverageMap.styles";
import MathUtils from "../../../utils/math";
import CircularProgress from "@mui/material/CircularProgress";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "Left",
  color: theme.palette.text.secondary,
}));
const COVERAGE = "coverage";
const DURATION = "duration";

function renderProgress(params) {
  let progress;
  switch (params.field) {
    case COVERAGE:
      progress = (<div style={{ display: "contents" }}>
        <span style={{ paddingRight: "1rem" }}>{params.value}%</span>
        <CustomizedProgressBars value={Number(params.value)} />
      </div>);
      break;
    case DURATION:
      const duration = params.value;
      const minSec = TimeUtil.secondsToMinutesAndSeconds(params.value);
      let value = Math.floor((duration / 120) * 100); //max duration is 2 minutes, hence 120 seconds.
      value = MathUtils.clamp(value, 0, 100); // ensures value remains within range of 0-100 for progress bar.
      progress = (<div style={{ display: "contents" }}>
          <span style={{ paddingRight: "1rem" }}>
            {minSec.minutes || 0}:{minSec.seconds}
          </span>
        <CustomizedProgressBars value={Number(value)} />
      </div>);
      break;
    default:
      progress = (<div style={{ display: "contents" }}>
        <span>{params.value}</span>
        <CustomizedProgressBars value={Number(params.value)} />
      </div>);
  }
  return <div style={{ display: "contents" }}>{progress}</div>;
}

/**
 * Color settings for the mouthmap.
 */
/**
 * Keep the filters in a hash map for easy access and updating.
 * When the filters are ready to be applied, use the applyFilters
 * method to update state and trigger a re-render.
 */
const filters = {
  coverage: null,
  duration: null,
};

class CoverageMap extends Component {
  constructor(props) {
    super(props);
    /*
    The default sorting as described at https://mui.com/components/data-grid/sorting/
    has had no effect. Hence, sorting the input array here until (if) we can figure out
    how to get the DataGrid to work as advertised.
    */
    if (this.props.brushings) {
      this.props.brushings.sort((a, b) => {
        return b.date - a.date;
      });
    }
    this.state = {
      columns: [{
        field: "date",
        headerName: "Day",
        type: "date",
        flex: 0.6,
        valueFormatter: (value) => moment(new Date(value.formattedValue)).format("ddd, MMM D, YYYY"),
      }, {
        field: "datetime",
        headerName: "Time",
        flex: 0.5,
        valueFormatter: (value) => moment(new Date(value.formattedValue)).format("hh:mm A"),
      }, {
        field: "coverage",
        headerName: "Coverage (%)",
        flex: 1,
        renderCell: renderProgress,
      }, {
        field: "duration",
        headerName: "Duration (Out of 2 min)",
        flex: 1,
        renderCell: renderProgress,
      }],
      selectionModel: [],
      selectedRow: this.props.brushings ? this.props.brushings[0] : null,
      checked: "",
      filters: [],
      showCoverageFilterMenu: false,
      coverageFilterMenuAnchorElement: null,
      showDurationFilterMenu: false,
      durationFilterMenuAnchorElement: null,
      errorCover: false,
      errorDura: false,
      open: false,
      coverageData: "",
      loading: true,
      mouthMapColorRange: {
        start: "#FF9650",
        end: "#FFFFFF",
      },
    };
    window.addEventListener("resize", this.update);
    this.onDataGridRowClicked = this.onDataGridRowClicked.bind(this);
    this.clearAllFilters = this.clearAllFilters.bind(this);
    this.handleDelete = this.deleteFilter.bind(this);
    this.toggleShowCoverageFilterMenu = this.toggleShowCoverageFilterMenu.bind(this);
    this.toggleShowDurationFilterMenu = this.toggleShowDurationFilterMenu.bind(this);
    this.onCoverageFilterMenuClosed = this.onCoverageFilterMenuClosed.bind(this);
    this.onDurationFilterMenuClosed = this.onDurationFilterMenuClosed.bind(this);
    this.filterRows = this.filterRows.bind(this);
    this.onCoverageFilterMinChanged = this.onCoverageFilterMinChanged.bind(this);
    this.onCoverageFilterMaxChanged = this.onCoverageFilterMaxChanged.bind(this);
    this.onDurationFilterMinMinutesChanged = this.onDurationFilterMinMinutesChanged.bind(this);
    this.onDurationFilterMinSecondsChanged = this.onDurationFilterMinSecondsChanged.bind(this);
    this.onDurationFilterMaxMinutesChanged = this.onDurationFilterMaxMinutesChanged.bind(this);
    this.onDurationFilterMaxSecondsChanged = this.onDurationFilterMaxSecondsChanged.bind(this);
    this.deleteFilter = this.deleteFilter.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.getLabelCoverageText = this.getLabelCoverageText.bind(this);
    this.getLabelCoverageTitleText = this.getLabelCoverageTitleText.bind(this);
    this.getLabelDurationText = this.getLabelDurationText.bind(this);
    this.getLabelDurationTitleText = this.getLabelDurationTitleText.bind(this);
  }

  /**
   * The brushings data is passed down from the parent component
   * since it is going to be used across multiple child components.
   * The data is loaded via an ajax call and is not avaiable in the
   * componentDidMount method which has been firing before the parent's
   * componentDidMount method. Hence, we watch for changes here and
   * update when the data changes.
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.brushings !== this.props.brushings) {
      if (!this.state.selectedRow.length === 0) {
        this.setState({
          selectedRow: this.props.brushings[0],
        });
      }
    }
  }

  //Filters
  /**
   * Replaces the state filters property to triger updates
   * using the filters hash map.
   *
   */
  applyFilters() {
    const newFilters = [];
    for (const f in filters) {
      const filter = filters[f];
      if (filter && filter.isValid) {
        newFilters.push(filter);
      }
    }
    this.setState({ filters: newFilters });
  }

  deleteFilter(filter) {
    if (filters[filter.property]) {
      filters[filter.property] = null;
    }
    this.applyFilters();
    this.setState({ errorCover: false });
    this.setState({ errorDura: false });
  }

  clearAllFilters = () => {
    for (let f in filters) {
      filters[f] = null;
    }
    this.applyFilters();
    this.setState({ errorCover: false });
    this.setState({ errorDura: false });
  };

  // Coverage filter
  onCoverageFilterMenuClosed() {
    this.state.filters.forEach((filter) => {
      if (filter.min > filter.max) {
        this.setState({ errorCover: true });
      }
    });
    this.setState({
      showCoverageFilterMenu: false,
      coverageFilterMenuAnchorElement: null,
    });
  }

  toggleShowCoverageFilterMenu(event) {
    const show = !this.state.showCoverageFilterMenu;
    const anchor = show ? event.target : null;
    this.setState({
      showCoverageFilterMenu: show,
      coverageFilterMenuAnchorElement: anchor,
      errorCover: false,
    });
  }

  onCoverageFilterMinChanged(event) {
    let filter = filters.coverage;
    if (!(filter instanceof CoverageMinMaxFilter)) {
      filter = new CoverageMinMaxFilter();
      filters.coverage = filter;
    }
    filter.min = event.target.value;
    this.applyFilters();
  }

  onCoverageFilterMaxChanged(event) {
    let filter = filters.coverage;
    if (!(filter instanceof CoverageMinMaxFilter)) {
      filter = new CoverageMinMaxFilter();
      filters.coverage = filter;
    }
    filter.max = event.target.value;
    this.applyFilters();
  }

  // Duration Filter
  onDurationFilterMenuClosed() {
    this.state.filters.forEach((filter) => {
      if (filter.minMinutes > filter.maxMinutes) {
        this.setState({ errorDura: true });
      }
    });
    this.setState({
      showDurationFilterMenu: false,
      durationFilterMenuAnchorElement: null,
    });
  }

  toggleShowDurationFilterMenu(event) {
    const show = !this.state.showDurationFilterMenu;
    const anchor = show ? event.target : null;
    this.setState({
      showDurationFilterMenu: show,
      durationFilterMenuAnchorElement: anchor,
      errorDura: false,
    });
  }

  onDurationFilterMinMinutesChanged(event) {
    let filter = filters.duration;
    if (!(filter instanceof DurationMinMaxMinutesSecondsFilter)) {
      filter = new DurationMinMaxMinutesSecondsFilter();
      filters.duration = filter;
    }
    filter.minMinutes = event.target.value;
    this.applyFilters();
  }

  onDurationFilterMinSecondsChanged(event) {
    let filter = filters.duration;
    if (!(filter instanceof DurationMinMaxMinutesSecondsFilter)) {
      filter = new DurationMinMaxMinutesSecondsFilter();
      filters.duration = filter;
    }
    filter.minSeconds = event.target.value;
    this.applyFilters();
  }

  onDurationFilterMaxMinutesChanged(event) {
    let filter = filters.duration;
    if (!(filter instanceof DurationMinMaxMinutesSecondsFilter)) {
      filter = new DurationMinMaxMinutesSecondsFilter();
      filters.duration = filter;
    }
    filter.maxMinutes = event.target.value;
    this.applyFilters();
  }

  onDurationFilterMaxSecondsChanged(event) {
    let filter = filters.duration;
    if (!(filter instanceof DurationMinMaxMinutesSecondsFilter)) {
      filter = new DurationMinMaxMinutesSecondsFilter();
      filters.duration = filter;
    }
    filter.maxSeconds = event.target.value;
    this.applyFilters();
  }

  onDataGridRowClicked(event) {
    this.setState({ selectedRow: event.row });
  }

  filterRows() {
    let rows = [];
    if (this.state.filters.length > 0) {
      rows = this.props.brushings.filter((row) => {
        for (const filter of this.state.filters) {
          let val = row[filter.property];
          if (val === undefined || val < filter.min || val > filter.max) {
            return false;
          }
        }
        return true;
      });
    } else {
      rows = [...this.props.brushings];
    }
    return rows;
  }

  getLabelCoverageText() {
    const filter = filters.coverage;
    var val = "";
    if (filter instanceof CoverageMinMaxFilter) {
      val = filter.label + "%";
    } else {
      val = "Coverage";
    }
    return val;
  }

  getLabelCoverageTitleText() {
    const filter = filters.coverage;
    var val = "";
    if (filter instanceof CoverageMinMaxFilter) {
      val = "Coverage";
    }
    return val;
  }

  getLabelDurationText() {
    const filter = filters.duration;
    var val = "";
    if (filter instanceof DurationMinMaxMinutesSecondsFilter) {
      val = filter.label;
    } else {
      val = "Duration(minutes)";
    }
    return val;
  }

  getLabelDurationTitleText() {
    const filter = filters.duration;
    var val = "";
    if (filter instanceof DurationMinMaxMinutesSecondsFilter) {
      val = "Duration(minutes)";
    }
    return val;
  }

  render() {
    const { classes } = this.props;
    if (!this.state.selectedRow) {
      return (<Card className="coverage-map-no-data-card">
        <CardContent>
          <Grid container direction="row" display="flex" className="coverage-map-no-data-grid">
            <div className="coverage-map-no-data-text">No Data Available for Selected Time Period</div>
          </Grid>
        </CardContent>
      </Card>);
    }
    const filteredRows = this.filterRows();
    const selectedBrushingDurationMinSec = TimeUtil.secondsToMinutesAndSeconds(this.state.selectedRow.duration);
    let jawSize = 210;
    if (window.innerWidth >= 1500) {
      jawSize = 370;
    } else if (window.innerWidth >= 1400) {
      jawSize = 300;
    }
    return (<ThemeProvider theme={theme}>
      <Box sx={{
        flexGrow: 1,
        width: "100%",
      }}>
        <Grid container spacing={2} className={classes.overAllPage}>
          <Grid item xs={7.5}>
            <Item style={{
              height: "82%",
              overflow: "hidden",
            }}>
              <Typography variant="h3" className="brushsession">
                Brushing Sessions
              </Typography>
              <Grid container direction="row" className={classes.firstsection}>
                <Grid item>
                  <Grid container spacing={4} direction="row" className={classes.root}>
                    <Grid item style={{ display: "flex" }}>
                      <Typography variant="h5">Filters</Typography>
                      <Box sx={{
                        width: 100,
                        marginLeft: "0rem",
                      }}>
                        <Box sx={{ position: "relative" }}>
                          {this.state.errorCover ? (<TextField error label={this.getLabelCoverageTitleText()}
                                                               onClick={this.toggleShowCoverageFilterMenu}
                                                               className="inputRounded"
                                                               value={this.getLabelCoverageText()}
                                                               helperText="Invalid entry" />) : (
                            <TextField label={this.getLabelCoverageTitleText()}
                                       onClick={this.toggleShowCoverageFilterMenu} className="inputRounded"
                                       value={this.getLabelCoverageText()} />)}
                          {this.state.showCoverageFilterMenu ? (
                            <ClickAwayListener onClickAway={this.onCoverageFilterMenuClosed}>
                              <Box className="filter-popup">
                                <div style={{
                                  display: "flex",
                                  direction: "row",
                                }}>
                                  <TextField autoFocus label="Min" variant="outlined"
                                             onChange={this.onCoverageFilterMinChanged} inputProps={{
                                    style: {
                                      height: "15px",
                                    },
                                  }} InputLabelProps={{
                                    style: {
                                      fontSize: "13px",
                                      shrink: true,
                                    },
                                  }} sx={{ width: "65px" }} />
                                  <img alt="simple-img" src={process.env.PUBLIC_URL + "/percent.png"}
                                       className="percent"></img>
                                </div>
                                <div>
                                  <img alt="simple-img" className="seprator"
                                       src={process.env.PUBLIC_URL + "/sep.png"}></img>
                                </div>
                                <div style={{
                                  display: "flex",
                                  direction: "row",
                                }}>
                                  <TextField label="Max" variant="outlined" onChange={this.onCoverageFilterMaxChanged}
                                             inputProps={{
                                               style: {
                                                 height: "15px",
                                                 borderRadius: "8.5px",
                                               },
                                             }} InputLabelProps={{
                                    style: { fontSize: "13px" },
                                  }} sx={{ width: "65px" }} />
                                  <img alt="simple-img" className="percent"
                                       src={process.env.PUBLIC_URL + "/percent.png"}></img>
                                </div>
                              </Box>
                            </ClickAwayListener>) : null}
                        </Box>
                      </Box>
                      <Box sx={{
                        width: 140,
                        marginLeft: "1rem",
                      }}>
                        <Box sx={{ position: "relative" }}>
                          {this.state.errorDura ? (<TextField error label={this.getLabelDurationTitleText()}
                                                              onClick={this.toggleShowDurationFilterMenu}
                                                              className="inputRounded" style={{ width: "170px" }}
                                                              value={this.getLabelDurationText()} InputLabelProps={{
                            style: { shrink: true },
                          }} helperText="Invalid Range" />) : (<TextField label={this.getLabelDurationTitleText()}
                                                                          onClick={this.toggleShowDurationFilterMenu}
                                                                          className="inputRounded"
                                                                          style={{ width: "170px" }}
                                                                          value={this.getLabelDurationText()}
                                                                          InputLabelProps={{
                                                                            style: { shrink: true },
                                                                          }} />)}
                          {this.state.showDurationFilterMenu ? (
                            <ClickAwayListener onClickAway={this.onDurationFilterMenuClosed}>
                              <Box className="duration-filter-popup" style={{
                                display: "flex",
                                direction: "row",
                              }}>
                                <fieldset style={{
                                  display: "flex",
                                  direction: "row",
                                  height: "80px",
                                  border: "none",
                                }}>
                                  <legend>Minimum</legend>
                                  <TextField autoFocus label="0" variant="outlined"
                                             onChange={this.onDurationFilterMinMinutesChanged} inputProps={{
                                    style: {
                                      height: "15px",
                                      borderRadius: "8.5px",
                                    },
                                  }} InputLabelProps={{
                                    style: {
                                      fontSize: "13px",
                                      shrink: true,
                                    },
                                  }} sx={{ width: "65px" }} />
                                  <img alt="simple-img" className="time-seprator"
                                       src={process.env.PUBLIC_URL + "/seprator.png"}></img>
                                  <TextField label="00" variant="outlined"
                                             onChange={this.onDurationFilterMinSecondsChanged} inputProps={{
                                    style: {
                                      height: "15px",
                                      borderRadius: "8.5px",
                                    },
                                  }} InputLabelProps={{
                                    style: { fontSize: "13px" },
                                  }} sx={{ width: "65px" }} />
                                </fieldset>
                                <img alt="simple-img" src={process.env.PUBLIC_URL + "/sep.png"}
                                     className="seprator"></img>
                                <fieldset style={{
                                  display: "flex",
                                  direction: "row",
                                  height: "80px",
                                  border: "none",
                                }}>
                                  <legend>Maximum</legend>
                                  <TextField label="0" variant="outlined"
                                             onChange={this.onDurationFilterMaxMinutesChanged} inputProps={{
                                    style: {
                                      height: "15px",
                                      borderRadius: "8.5px",
                                    },
                                  }} InputLabelProps={{
                                    style: { fontSize: "13px" },
                                  }} sx={{ width: "65px" }} />
                                  <img alt="simple-img" src={process.env.PUBLIC_URL + "/seprator.png"}
                                       className="time-seprator"></img>
                                  <TextField label="00" variant="outlined"
                                             onChange={this.onDurationFilterMaxSecondsChanged} inputProps={{
                                    style: {
                                      height: "15px",
                                      borderRadius: "8.5px",
                                    },
                                  }} InputLabelProps={{
                                    style: { fontSize: "13px" },
                                  }} sx={{ width: "65px" }} />
                                </fieldset>
                              </Box>
                            </ClickAwayListener>) : null}
                        </Box>
                      </Box>
                    </Grid>
                    {this.state.filters.length > 0 ? (<Grid item style={{ marginTop: "8px" }}>
                      <Button variant="subtitle1" onClick={this.clearAllFilters} className={classes.clearButton}>
                        Clear All
                      </Button>
                    </Grid>) : null}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.tableData}>
                <DataGrid rows={filteredRows} columns={this.state.columns} onRowClick={this.onDataGridRowClicked}
                          density="compact" selectionModel={this.state.selectedRow.id} disableColumnMenu
                          onSelectionModelChange={(e) => {
                            this.setState({ selectionModel: e });
                            const selectedIDs = new Set(e);
                            const selectedRows = filteredRows.filter((r) => selectedIDs.has(r.id));
                            this.setState({ selectedRows: selectedRows });
                          }} className={classes.tableData} />
              </Grid>
            </Item>
          </Grid>
          <Grid item xs={4.5}>
            <Item style={{
              backgroundColor: "#FFFFFF",
              height: "82%",
              overflow: "hidden",
            }}>
              <Grid container direction="row" justifyContent="space-between" display="flex"
                    className={classes.secondCard}>
                <Grid item>
                  <Typography variant="h5" marginLeft="-10px">
                    {moment(this.state.selectedRow.date).format("ddd, MMM D,yy")}
                    <Typography>
                      {moment(this.state.selectedRow.datetime).format("hh:mm a")}
                    </Typography>
                  </Typography>
                  <Typography variant="h5">
                    {this.state.selectedRow.brushingTime}
                  </Typography>
                </Grid>
                <Grid item style={{
                  marginTop: "-0.5rem",
                  marginLeft: "-2rem",
                }}>
                    <span className={classes.mouthmapcover} style={{ paddingLeft: "1.0rem" }}>
                      {this.state.selectedRow.coverage}%
                      <div className={classes.mouthcovertitle} style={{ paddingLeft: "0.4rem" }}>
                        COVERAGE
                      </div>
                    </span>
                </Grid>
                <Grid item style={{ marginTop: "-0.5rem" }}>
                    <span className={classes.mouthmapcover}>
                      {selectedBrushingDurationMinSec.minutes !== "00" ? `${selectedBrushingDurationMinSec.minutes}:${selectedBrushingDurationMinSec.seconds} min` : `${selectedBrushingDurationMinSec.seconds} sec`}
                      <div className={classes.mouthcovertitle}>DURATION</div>
                    </span>
                </Grid>
              </Grid>
              <Card className={classes.mouthmapcard}>
                <CardContent>
                  <Grid container direction="row" display="flex" justifyContent="space-between" alignItems="center"
                        className={classes.cardpage}>
                    <Grid item style={{
                      paddingTop: "30%",
                    }}>
                      <img alt="left" src={process.env.PUBLIC_URL + "/left.png"}></img>
                    </Grid>
                    {this.state.loading ? (<CircularProgress style={{ marginTop: "30%" }} />) : (<Grid item>
                      <DraggableJaw size={jawSize} brushingResult={this.state.selectedRow.brushingResult}
                                    colorRange={this.state.mouthMapColorRange}></DraggableJaw>
                    </Grid>)}
                    <Grid item style={{
                      paddingTop: "30%",
                    }}>
                      <img alt="right" src={process.env.PUBLIC_URL + "/right.png"}></img>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Grid container direction="row" justifyContent="space-evenly" className={classes.declaration}>
                <Grid item xs={5}>
                  <div style={{
                    background: `linear-gradient(to right, ${this.state.mouthMapColorRange.end} 40%, ${this.state.mouthMapColorRange.start}) 100%`,
                    border: "1px solid #ccc",
                  }}>
                    &nbsp;
                  </div>
                  <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                    <span style={{ fontSize: "13px" }}>Clean</span>
                    <span style={{ fontSize: "13px" }}>Missed</span>
                  </div>
                </Grid>
                <Grid item xs={5} textAlign="end">
                    <span style={{
                      fontFamily: "Roboto, sans-serif !important",
                      fontStyle: "Regular",
                      fontSize: "10px",
                      lineHeight: "19.6px",
                      align: "Right",
                      verticalAlign: "Top",
                      color: "#818282",
                    }}>
                      Left and right shown from patient's perspecive.
                    </span>
                </Grid>
              </Grid>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>);
  }
}

export default withStyles(styles)(CoverageMap);
